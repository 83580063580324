import React from 'react';
import type { FC } from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe, faPhone } from '@fortawesome/free-solid-svg-icons';
import { down } from 'styled-breakpoints';
import { getProfileImageText, isValidURL } from "../../utils/common";
interface VendorProps {
  image: string;
  name: string;
  phone: string;
  email: string;
  website: string;
  padding?: string;
  apiImageUrl?: boolean;
  alternativeText: string;
}

const VendorCard = styled.div<VendorProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: -1px;
  margin-bottom: -1px;
  text-decoration:none;
  padding: ${(props: any) => (props.padding ? props.padding : '16px')} ;
  ${down('md')}{
    padding: ${(props: any) => (props.padding ? props.padding : '10px')} ;
  }
  img {
    margin-bottom: 16px;
    float:left;
  }
  h3 {
    font-size: 24px;
  }
`;

const VendorContent = styled.div`
  text-align: left;
  min-height: 5rem;
  height:100%;
  ${down('md')}{
    min-height: auto;
  }
  `;

const Vendor = styled.div`
    text-align: center;
    width: 100%;
    height: 100%;
    background: ${(props: any) => props.theme.colors.white};
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
    padding: 2rem;
    ${down('md')}{
      padding: 1rem;
    }
`;

const VendorName = styled.h3`
min-height: 80px;
font-family: 'Oswald', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 34px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
color: ${(props: any) => props.theme.colors.greyText};
`;

const ProfilePicWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const ProfilePic = styled.div`
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: ${(props: any) => props.theme.colors.pattenBlue};
    border: 1px solid ${(props: any) => props.theme.colors.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    span {
      font-family: 'Oswald', sans-serif;
      font-weight: 400;
      font-size: 28px;
      line-height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${(props: any) => props.theme.colors.blue};
      height: 100%;
    width: 100%;
      img {
        max-width:100%;
        height:100%;
        object-fit:contain;
        padding: 4px;
        margin-bottom: 0;
      }
  }
`;

const ProfileLogo = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
      img {
        max-width:100px;
        max-height:100px;
        padding: 4px;
        margin-bottom: 0;
      }
  }
`;

const VendorCardDividerWrapper = styled.div`
    margin: auto;
`;

const VendorCardDivider = styled.hr`
    margin-bottom: 25px;
    border: 0;
    height: 1px;
    background-color: ${(props: any) => props.theme.colors.brightGrey};
`;


const VendorInfo = styled.ul`
    padding: 0;
`;
const VendorInfoList = styled.li`
list-style: none;
display: flex;
justify-content: start;
`;

const VendorInfoLink = styled.a`
  text-decoration: none;
  display: flex;
  // align-items: center;
  margin-bottom: 1.5rem;
  // white-space: nowrap;
  word-break: break-word;
  // max-width: 90%;
`;

const VendorInfoText = styled.span`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: ${(props: any) => props.theme.colors.greyText};
 
`;

const IconWrapper = styled.div`
  font-size: 1.3rem;
  margin-right: 1rem;
  flex-shrink :0;
  svg {
    color : ${(props: any) => props.theme.colors.blue};
  }
  `;

const Service: FC<VendorProps> = ({ apiImageUrl, alternativeText, website, email, image, name, phone, padding }) => {
  const profileName = getProfileImageText(name);
  return (
    <VendorCard>
      <VendorContent>
        <Vendor>
          <ProfilePicWrap>
            {image ?
              <ProfileLogo>
                <img src={image} alt={alternativeText} />
              </ProfileLogo>
              :
              <ProfilePic>
                <span>{profileName || ""}</span>
              </ProfilePic>
            }
          </ProfilePicWrap>
          <VendorName>{name}</VendorName>
          <VendorCardDividerWrapper>
            <VendorCardDivider />
            <VendorInfo>
              <VendorInfoList>
                {email ?
                  <VendorInfoLink href={`mailto:${email}`}>
                    <IconWrapper>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </IconWrapper>
                    <VendorInfoText>
                      {email}
                    </VendorInfoText>
                  </VendorInfoLink>
                  : ""}
              </VendorInfoList>
              <VendorInfoList>
                {phone ?
                  <VendorInfoLink href={`tel:${phone}`}>
                    <IconWrapper>
                      <FontAwesomeIcon icon={faPhone} />
                    </IconWrapper>
                    <VendorInfoText>
                      {phone}
                    </VendorInfoText>
                  </VendorInfoLink>
                  : ""}
              </VendorInfoList>
              <VendorInfoList>
                {website ?
                  <VendorInfoLink href={isValidURL(website) ? website : '//' + website} target="_blank" rel="nofollow">
                    <IconWrapper>
                      <FontAwesomeIcon icon={faGlobe} />
                    </IconWrapper>
                    <VendorInfoText>{website}</VendorInfoText>
                  </VendorInfoLink>
                  : ""}
              </VendorInfoList>
            </VendorInfo>
          </VendorCardDividerWrapper>

        </Vendor>
      </VendorContent>


    </VendorCard>
  );
};

export default Service;