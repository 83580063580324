import React, { FC } from "react";
import styled from "styled-components";
import { Flex } from 'rebass';
import Container from "../../components/elements/Container";
import Grid from "../../components/elements/Grid";
import Vendor from "../../components/elements/vendor";
import Heading from "../../components/elements/Heading";
import { theme } from "../../theme";
import Description from "../elements/Description";
import { VendorData } from "../../types/vendors";
interface VendorsSectionProps {
  vendorsRecord?: VendorData;
}
const VendorsWrap = styled.section`
  width: 100%;
  padding: 60px 0px 100px 0px;
  color: ${(props: any) => props.theme.colors?.white};

  position: relative;
  .banner-background {
    padding: ${(props: any) => props.theme.spacing[3]}rem 0;
    //background: ${(props: any) => props.theme.colors.primaryGradient};
  }
  .banner-content {
    max-width: 28rem;
    text-shadow: ${(props: any) => props.theme.text.shadow};
  }
`;

const VendorsSection: FC<VendorsSectionProps> = ({ vendorsRecord }) => {

  return (
    <VendorsWrap>
      <Container>
      <Heading margin="12px auto" textAlign={"center"} textFontWeight={theme.fontWeight.medium} boldFontWeight={theme.fontWeight.bold} fontSize={'1.5rem'} textColor={theme.colors.blue} text={"Our"} boldText={"Vendors"} boldTextColor={theme.colors.blue} />
        <Description textAlign="center" margin={"10px auto"} textColor={theme.colors.mediumGray} text={"We work with the following vendors to create your customized pool experience."} />
        <Flex flexWrap={['wrap', 'wrap', 'nowrap']} mt={5}>
          <Grid columns="3" style={{ gridColumnGap: "0px", gridRowGap: "0px" }}>
            {vendorsRecord && vendorsRecord?.data.map((vender: any, index: number) => {
              //if (index < showServicesCount) {
              return <Vendor
                key={index}
                padding={'30px 30px 30px 30px'}
                name={vender?.attributes?.name}
                phone={vender?.attributes?.phone}
                email={vender?.attributes?.email}
                website={vender?.attributes?.website}
                image={vender?.attributes?.media?.data?.attributes?.url}
                alternativeText={vender?.attributes?.media?.data?.attributes?.alternativeText}
                apiImageUrl={true}
              />;
              //}
            })}
          </Grid>
        </Flex>
      </Container>
    </VendorsWrap>
  );
};

export default VendorsSection;

