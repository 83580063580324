import React from "react";
import { theme } from "../../theme";
import HeaderWithImage from "../../components/composites/HeaderWithImage";
import Heading from "../../components/elements/Heading";
import Description from "../../components/elements/Description";
const Banner = ({ bannerData }: any) => {

  return (
    <>
      <HeaderWithImage
        bgImage={bannerData?.bgImage}
        bgThumbnail={bannerData?.thumbnail}>
        <Heading HeadingType={"banner"} textAlign={"center"} textFontWeight={theme.fontWeight.light} boldFontWeight={theme.fontWeight.bold} fontFamily={theme.font.families.oswald} fontSize={'3rem'} textColor={theme.colors.white} text={"OUR"} boldText={"VENDORS"} boldTextColor={theme.colors.white} />
        <Description textAlign="center" margin={`${theme.spacing[1]}rem auto`} lineHeight={2} textColor={theme.colors.white} textFontWeight={theme.fontWeight.light} text={bannerData?.desc} maxWidth={'75%'} />
      </HeaderWithImage>
    </>
  );
};

export default Banner;