import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VendorSection from "../../components/composites/VendorSection";
import { VendorData } from "../../types/vendors";

const Vendor = () => {
  const vendorPageSize = 100;
  const [vendorRecord, setVendorRecord] = useState<VendorData>();
  const notify = (message: string) => toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });

  useEffect(() => {
    (async () => {
      const msg = `An error while retrieving services content!`;
      try {
        const params = {
          "populate": "media,media.file",
          //"pagination[limit]": galleryLimit,
          "pagination[pageSize]": vendorPageSize,
          "sort": "id:desc"
        };
        const { status, data } = await api("/api/vendors", "get", params);
        if (status >= 200 && status <= 299) {
          setVendorRecord(data);
        } else {
          notify(msg);
        }
      } catch (err) {
        console.log(err);
        notify(msg);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <VendorSection vendorsRecord={vendorRecord} />
  );
};

export default Vendor;