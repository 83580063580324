import { Pagination } from "./common";
export interface PageMetaData {
  data: DataObject[],
  meta: Pagination,
}

export interface DataObject {
  id: number,
  attributes: AttributesObject,
}

export interface AttributesObject {
  metaTitle: string,
  metaDesc: string,
  metaKeywords: string,
  page: string,
  desc: string,
  createdAt: string,
  updatedAt: string,
  media: MediaFileDataObject,
}

export interface MediaFileDataObject {
  data: MediaDataObject;
}

export interface MediaDataObject {
  id: number,
  attributes: MediaAttributes;
}

export interface MediaAttributes {
  name: string;
  alternativeText: string,
  caption?: string,
  width?: number,
  height?: number,
  ext?: string,
  createdAt?: string,
  updatedAt?: string,
  mime?: string,
  url: string,
  formats: FormatsObject,
}

export interface FormatsObject {
  thumbnail: ThumbnailObject,
  small: SmallObject,
  medium: MediumObject,
}

export interface MediumObject {
  name?: string;
  width?: number,
  height?: number,
  ext?: string,
  mime?: string,
  url: string,
}

export interface SmallObject {
  name?: string;
  width?: number,
  height?: number,
  ext?: string,
  mime?: string,
  url: string,
}
export interface ThumbnailObject {
  name?: string;
  width?: number,
  height?: number,
  ext?: string,
  mime?: string,
  url: string,
}

export enum PageTypes {
  Home = 'home',
  Services = 'services',
  Gallery = 'gallery',
  Vendors = 'vendors',
  ContactUs = 'contact us',
  AboutUs = 'about us'
}
