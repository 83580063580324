import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
interface GridProps {
	children?: ReactNode;
	columns?: string;
	style?: any;
	type?: string;
	gridAutoFlow?: string;
	gridResponsiveFlow?: string;
}

const GridWrapper = styled.section`
  overflow: hidden;
  display: grid;
  grid-template-columns: ${(props: any) => props.type === "auto" ? '33.3% 33.3% 33.4%' : `repeat(${props.columns}, 1fr)`};
	grid-auto-flow: ${(props: any) => props.gridAutoFlow ? props.gridAutoFlow : 'row'};
  grid-template-rows: auto auto;
	width: 100%;
  ${down('lg')}{
	grid-template-columns: repeat(2,1fr);
	grid-auto-flow:${(props: any) => (props.gridResponsiveFlow)} !important;
	width: 100%;
	}
  }
  ${down('md')}{
	grid-template-columns: repeat(1,1fr);
	grid-auto-flow:${(props: any) => (props.gridResponsiveFlow)} !important;
	}
  }
`;

const Grid: FC<GridProps> = ({ columns, type, children, style, gridAutoFlow, gridResponsiveFlow }) => {
	return (
		<GridWrapper columns={columns} type={type} style={style} gridAutoFlow={gridAutoFlow} gridResponsiveFlow={gridResponsiveFlow} >
			{children}
		</GridWrapper >
	);
};

Grid.propTypes = {
	children: PropTypes.node,
	columns: PropTypes.string,
	style: PropTypes.any
};


export default Grid;
